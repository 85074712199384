<script>
import api from "../api";
import Loader from "vue-element-loading";
import AInput from "ant-design-vue/lib/input";
import ARadio from "ant-design-vue/lib/radio";
import AFormModel from "ant-design-vue/lib/form-model";
import AFormModelItem from "ant-design-vue/lib/form-model/FormItem";
import Message from "ant-design-vue/lib/message";
export default {
  components: {
    AInput,
    ARadio,
    AFormModel,
    AFormModelItem,
    Loader,
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the password"));
      } else {
        if (this.ruleForm.confirmPassword !== "") {
          this.$refs.ruleForm.validateField("confirmPassword");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the password again"));
        this.submitDisabled = true;
      } else if (value !== this.ruleForm.password) {
        callback(new Error("Two inputs don't match!"));
        this.submitDisabled = true;
      } else {
        callback();
        this.submitDisabled = false;
      }
    };
    return {
      // email: "",
      title: "",
      loading: false,
      emailDisabled: true,
      ruleForm: { password: "", confirmPassword: "" },
      validationRules: {
        password: [{ validator: validatePass, trigger: "change" }],
        confirmPassword: [{ validator: validatePass2, trigger: "change" }],
      },
      submitDisabled: true,
      returnEmail: "",
      currentEmail: "",
      hide: true,
    };
  },
  computed: {
    isFirstTime() {
      return this.$route.params.isFirstTime;
    },
    hasPassword() {
      return this.$route.params.hasPassword;
    },
  },
  methods: {
    setEmail() {
      this.currentEmail = this.$route.params.email;
      if (!this.currentEmail){
        this.currentEmail = this.$store.state.Auth.email;
      }
    },
    setTitle() {
      const firstTimeTitle = "Now just set up a password, and you're all set.";
      const hasPasswordTitle =
        "Need to change your password? Complete the form to save!";

      if (this.isFirstTime) {
        this.title = firstTimeTitle;
      }
      if (this.hasPassword) {
        this.title = hasPasswordTitle;
      }
    },
    useOther() {
      this.hide = false;
    },
    submit() {
      this.loading = true;
      // let email = this.currentEmail
      const data = {
        email: this.currentEmail,
        password: this.ruleForm.password,
        returnEmail: this.returnEmail,
      };
      api
        .saveReturnDetails(data)
        .then(() => {
          this.loading = false;
          Message.success("Successfully created user!");
          this.$router.push({ name: "FirstTimeCustomer" });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 500) {
            this.loading = false;
            Message.warning("Oops Bumped into an error, Try again");
          } else if (error.response.status === 404) {
            this.loading = false;
            Message.warning(this.response.message);
          }
        });
    },
  },
  created() {
    this.setEmail();
    this.setTitle();
    this.$store.commit("Nav/SET_PAGE", "setup"),
      this.$store.commit("Nav/SET_ACTIVE", 4);
  },
};
</script>
<template>
  <div id="setup-password">
    <div class="form-area">
      <loader :active="loading" spinner="ring" />
      <h1 class="title">
        {{ title }}
      </h1>
      <div class="group">
        <div class="inner">
          <h2 class="form-label">Last Order Email</h2>
          <a-input
            class="input-medium"
            :disabled="emailDisabled"
            v-model="currentEmail"
          >
            <i class="fas fa-envelope" slot="prefix" />
          </a-input>
        </div>
      </div>
      <div class="group" :class="{ hide: hide }">
        <div class="inner">
          <h2 class="form-label">Other Email</h2>
          <a-input class="input-medium other_email" v-model="returnEmail">
            <i class="fas fa-envelope" slot="prefix" />
          </a-input>
        </div>
      </div>
      <div class="group">
        <div class="inner">
          <a-radio class="use-other" @change="useOther"
            >Use another email</a-radio
          >
        </div>
      </div>
      <a-form-model
        ref="ruleForm"
        :model="ruleForm"
        :rules="validationRules"
        class="group"
      >
        <h2 class="form-label">Password</h2>
        <a-form-model-item has-feedback prop="password" class="input-medium">
          <a-input
            v-model="ruleForm.password"
            type="password"
            autocomplete="off"
          />
        </a-form-model-item>
        <h2 class="form-label">Confirm Password</h2>
        <a-form-model-item
          has-feedback
          prop="confirmPassword"
          class="input-medium"
        >
          <a-input
            v-model="ruleForm.confirmPassword"
            type="password"
            autocomplete="off"
          />
        </a-form-model-item>
      </a-form-model>

      <div class="group">
        <a
          href=""
          class="blue-button"
          :class="{ disabled: submitDisabled }"
          @click.prevent="submit"
          >Submit</a
        >
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../styles/_mixins.scss";
@import "../assets/styles/_utilities.scss";
@import "../assets/styles/_components.scss";
@import "@/assets/styles/_formTools";
#setup-password {
  position: relative;
  top: 8.4rem;
  font-family: var(--montserrat);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.title {
  font-size: 2.2rem;
}
.form-label {
  // align-self: center;
}
.new-input {
  width: 100%;
}
.hide {
  display: none;
}
</style>
